<template>
  <!-- index.vue -->

  <v-app
    :style="{
      background: $vuetify.theme.themes[theme].background,
      color: $vuetify.theme.themes[theme].color
    }"
    :class="{
      'app-admin-wrap-layout-1 sidebar-mini': getThemeMode.verticalSidebarMini,
      'sidebar-close': !getThemeMode.verticalSidebarDrawer
    }"
  >
    <side-bar>
      <!-- -->
    </side-bar>

    <app-bar class="">
      <!-- -->
    </app-bar>
    <!-- <sub-header>

    </sub-header> -->
    <!-- Sizes your content based upon application components -->

    <base-view />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import BaseView from "./View";
import SideBar from "./Sidebar";
import AppBar from "./AppBar";

export default {
  components: {
    BaseView,
    SideBar,
    AppBar
    // SubHeader: () => import("./SubHeader")
  },

  computed: {
    ...mapGetters(["getThemeMode"]),

    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    }
  }
};
</script>

<style lang="scss" scoped></style>
