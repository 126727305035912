<template>
  <v-dialog v-model="show" width="800" persistent>
    <v-card>
      <v-card-title class="headline lighten-2">
        {{ $t('app.api_for_webmasters') }}
      </v-card-title>

      <v-card-text class="pt-4">
        <ca-alert v-if="error" type="error">{{ error }}</ca-alert>

        <div v-html="$t('app.api_usage_description', {link: this.apiDocsLink})">
        </div>

        <div style="text-align: right;" class="mt-3">
          <v-btn v-if="!tokenCreated" color="primary" :loading="creatingApiToken" @click="createApiToken">
            {{ $t('app.get_api_key') }}
          </v-btn>

          <v-btn v-else color="success" outlined style="cursor: default;">
            {{ $t('app.api_key_has_been_sent_to_email') }}
          </v-btn>

          <v-btn @click="closeDialog" class="ml-2">
            {{$t('common.close')}}
          </v-btn>
        </div>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CaAlert from "@/views/components/Alert.vue";

export default {
  components: {CaAlert},
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      apiModal: true,
      creatingApiToken: false,
      tokenCreated: false,
      error: null,
    }
  },

  computed: {
    apiDocsLink() {
      return this.user.api_docs_url
    },

    user() {
      return this.$store.state.auth.userData;
    },
  },

  methods: {
    createApiToken() {
      this.creatingApiToken = true
      this.tokenCreated = false
      this.error = null

      this.viaJwtToken().then((data) => {
        this.axios.post(data.path + '/token/create', {jwt_token: data.jwt_token}, {withCredentials: false})
          .then(({data}) => {
            this.tokenCreated = true

            this.axios.post('/fd-api/send-email-with-api-token', {jwt_token: data.jwt_token})
              .then(({data}) => {
                this.creatingApiToken = false
              })
              .catch(() => {
                this.creatingApiToken = false
                this.errors = this.$t('common.try_later');
              });
          })
          .catch((error) => {
            this.creatingApiToken = false

            this.error = this.$t('common.try_later');
          });
      })
    },

    viaJwtToken() {
      return new Promise((resolve, reject) => {
        this.axios.get('/fd-api/get-jwt-token')
          .then(({data}) => {
            resolve(data)
          })
          .catch((error) => {
            this.creatingApiToken = false
            this.error = this.$t('common.try_later');

            console.log(error)

            if (error.response.status === 429) {
              this.error = error.response.data.message
            }  else {
              this.error = this.$t('common.try_later');
            }

            reject()
          });
      });
    },

    closeDialog() {
      this.$emit('closed')
    }
  }
}
</script>
