<template>
  <div>
    <v-app-bar
      :color="$vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor"
      :dark="getThemeMode.appBarColor != 'white' ? true : false"
      app
      class="px-sm text-left shadow-sm"
      flat
      height="75"
    >
      <v-app-bar-nav-icon v-ripple="{ class: 'primary--text' }" @click="toggleVerticalSidebarDrawer" />
      <vue-navigation-bar class="mt-0" :options="navbarOptions" @vnb-item-clicked="vnbItemClicked" />
      <v-progress-linear
        :active="getThemeMode.isLoading"
        :indeterminate="getThemeMode.isLoading"
        absolute
        bottom
        color="primary"
      />

      <!-- <v-toolbar-title>Egret Vue</v-toolbar-title> -->

      <v-spacer />

      <v-btn icon :href="$t('app.feedback_link')" target="_blank" :title="$t('app.feedback_link_text')">
        <v-icon>mdi-bug-outline</v-icon>
      </v-btn>

      <ca-lang-selector />

      <v-badge
        bordered
        overlap
        :content="user.unread_messages_count > 9 ? '9+' : user.unread_messages_count"
        :value="user.unread_messages_count"
        color="red"
        offset-x="22"
        offset-y="15"
      >
        <v-btn
         class="v-avatar mr-2 danger"
         @click="notificationDrawer = !notificationDrawer"
        >
          <span class="white--text text-20">{{ user.email[0] | toLowerCase }}</span>
        </v-btn>
      </v-badge>

      <v-btn class="d-xs-display d-sm-none d-lg-none d-md-none d-xl-none" icon @click="userDrawer = !userDrawer">
        <v-icon>mdi-account-outline</v-icon>
      </v-btn>

      <v-chip pill class="transparent py-5 d-xs-none" @click="userDrawer = !userDrawer">
        {{ user.email }} &nbsp; <em>({{ user.balance }}$)</em>
      </v-chip>
    </v-app-bar>
    <!-- userDrawer -->
    <v-navigation-drawer v-model="userDrawer" fixed right height="100%" temporary floating width="350">
      <user-drawer>
        <template #userDrawerCloseButton>
          <v-btn icon color @click.stop="userDrawer = !userDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template #append>
        <div class="my-4 mx-4">
          <base-hover-button
            :text="$t('app.logout')"
            block
            bg-color="primary lighten-5 primary--text"
            icon-name="mdi-logout"
            @click.native="logoutUser"
          />
        </div>
      </template>
    </v-navigation-drawer>

    <!-- notificationDrawer  -->
    <v-navigation-drawer v-model="notificationDrawer" fixed right height="100%" temporary floating width="350">
      <notification-drawer>
        <template #notificationDrawerCloseButton>
          <v-btn icon color @click.stop="notificationDrawer = !notificationDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </notification-drawer>

      <template #append>
        <div class="my-4 mx-4">
          <base-hover-button
            :text="$t('app.view_notifications')"
            block
            bg-color="primary lighten-5 primary--text"
            @click.native="goToNotificationsPage"
          />
        </div>
        <div class="my-4 mx-4">
          <base-hover-button
            :text="$t('app.mark_as_read')"
            block
            bg-color="success lighten-2"
            @click.native="markMessagesAsRead()"
          />
        </div>
      </template>
    </v-navigation-drawer>

    <ApiTokenModal :show="apiModal" @closed="apiModal = false" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CaLangSelector from "@/views/components/LangSelector";
import CaAlert from "@/views/components/Alert.vue";
import ApiTokenModal from "@/views/partials/ApiTokenModal.vue";

export default {
  name: "VerticallAppBar",
  components: {
    CaAlert,
    CaLangSelector,
    UserDrawer: () => import("../common-drawer/UserDrawer.vue"),
    NotificationDrawer: () => import("../common-drawer/NotificationDrawer.vue"),
    ApiTokenModal,
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
    user() {
      return this.$store.state.auth.userData;
    },
    navbarOptions() {
      let toolsMenu = [];

      let options = {
        elementId: "main-navbar",
        isUsingVueRouter: true,
        mobileBreakpoint: 992,
        brandImagePath: "./",
        brandImageAltText: "brand-image",
        collapseButtonOpenColor: "#661c23",
        collapseButtonCloseColor: "#661c23",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "Main Navigation",
        tooltipAnimationType: "shift-away",
        menuOptionsLeft: [
          {
            type: "link",
            text: this.$t("app.file_manager"),
            path: { name: "get-started" },
            iconLeft: '<i class="mdi mdi-file-upload-outline"></i>'
          },
          {
            type: "link",
            text: this.$t("app.news"),
            path: { name: "news" },
            iconLeft: '<i class="mdi mdi-bell ' + (this.user.has_unread_news ? "text-danger" : "") + '"></i>'
          },
          {
            type: "link",
            text: this.$t("app.help"),
            iconLeft: '<i class="mdi mdi-lifebuoy"></i>',
            subMenuOptions: [
              {
                type: "link",
                text: this.$t("app.get_started"),
                path: { name: "get-started" },
                iconLeft: '<i class="mdi mdi-flag-variant-outline"></i>'
              },
              {
                type: "link",
                text: this.$t("app.faq"),
                path: { name: "faq" },
                iconLeft: '<i class="mdi mdi-book-open-variant"></i>'
              },
              {
                type: "link",
                text: this.$t("app.support_center"),
                path: { name: "faq" },
                iconLeft: '<i class="mdi mdi-face-agent"></i>',
                isLinkAction: true
              }
            ]
          },
        ]
      };

      if (this.user.app_users_id.fd1) {
        toolsMenu.push({
          type: "link",
          text: this.$t("app.menu.fd1_login"),
          path: { name: "get-started" },
          iconLeft: '<i class="mdi mdi-circle-medium"></i>',
          isLinkAction: true
        });
      }

      if (this.user.app_users_id.fd2) {
        toolsMenu.push({
          type: "link",
          text: this.$t("app.menu.fd2_login"),
          path: { name: "get-started" },
          iconLeft: '<i class="mdi mdi-circle-medium"></i>',
          isLinkAction: true
        });
      }

      if (toolsMenu.length > 0) {
        options.menuOptionsLeft.push({
          type: "link",
          text: this.$t("app.menu.tools"),
          iconLeft: '<i class="mdi mdi-login"></i>',
          path: { name: "get-started" },
          subMenuOptions: toolsMenu
        });
      }

      options.menuOptionsLeft.push({
        type: "link",
        text: this.$t("app.api"),
        path: '',
        iconLeft: '<i class="mdi mdi-code-braces"></i>'
      });

      return options;
    }
  },
  data() {
    return {
      userDrawer: false,
      notificationDrawer: false,
      apiModal: false,
    };
  },
  methods: {
    ...mapActions(["changeVerticalSidebarDrawer", "changeVerticalSidebarMini"]),
    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer();

      // this.$emit("update:mini-variant");
      // console.log("check");
    },
    logoutUser() {
      this.$store.dispatch("auth/logOut").then(() => {
        this.$router.push({ name: "login" });
      });
    },
    loginTo(app) {
      this.$store.dispatch("auth/loginToApp", { app });
    },
    goToNotificationsPage() {
      if (this.$route.name === "notifications") {
        return;
      }

      this.$router.push({ name: "notifications" });
    },
    markMessagesAsRead() {
      this.$store.dispatch("app/markUserMessagesAsRead").then(() => {
        this.$store.dispatch("auth/loadUserData");
      });
    },
    /**
     * Обработка клика по пункту меню
     */
    vnbItemClicked(menuItemText) {
      if (menuItemText === this.$t("app.file_manager")) {
        this.loginTo("file_manager");
      }
      if (menuItemText === this.$t("app.menu.fd1_login")) {
        if (!this.user.app_users_id.fd1) {
          alert('test')
          return
        }

        this.loginTo("fd1");
      }
      if (menuItemText === this.$t("app.menu.fd2_login")) {
        this.loginTo("fd2");
      }

      if (menuItemText === this.$t("app.support_center")) {
        window.open("https://help2.costaction.com", "_blank");
      }

      if (menuItemText === this.$t("app.api")) {
        this.$store.dispatch("app/doSecureAction", {
          callback: () => {
            this.apiModal = true
          },
          mode: 'code'
        });
      }
    },
  }
};
</script>

<style lang="scss">
.tippy-box[data-theme~="light"] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.vnb {
  background: transparent !important;
  &__menu-options {
    margin-top: 3px !important;
    &__option {
      &__link {
        color: #595959 !important;
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff !important;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: #000 !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }
  &:after {
    content: "\F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }
  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}

.v-badge {
  .v-avatar {
    height: 36px;
    min-width: 36px !important;
    width: 36px;
  }
}
</style>
