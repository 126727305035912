<template>
  <ca-alert v-if="mainAlert.visible" :type="mainAlert.type">
    <v-row>
      <v-col class="grow" style="padding: 0">
        {{ mainAlert.message }}
      </v-col>

      <v-col v-if="mainAlert.dismissible" class="shrink mr-4" style="padding: 0">
        <button
          type="button"
          class="v-alert__dismissible v-btn v-btn--icon v-btn--round theme--light v-size--small info--text"
          aria-label="Close"
          @click="close"
        >
          <span class="v-btn__content"
            ><i aria-hidden="true" class="v-icon notranslate mdi mdi-close-circle theme--light info--text"></i
          ></span>
        </button>
      </v-col>
    </v-row>
  </ca-alert>
</template>

<script>
import CaAlert from "@/views/components/Alert";

export default {
  name: "CaMainAlert",
  components: { CaAlert },
  computed: {
    mainAlert() {
      return this.$store.state.app.mainAlert;
    }
  },
  methods: {
    close() {
      this.$store.dispatch("app/hideMainAlert");
    }
  }
};
</script>
